<template>
  <div>
    <a-table :columns="columns"
             :data-source="productList"
             :scroll="{ x: 1000, y: height }"
             :pagination="false"
                   size="small"
             :loading="productListLoading"
             class="product-table">
      <!-- header -->
      <template #nameAndNo>
        <div>{{$t('warehouse.product_name')}}/{{$t('common.product_no')}}</div>
      </template>
      <template #productInfo="{ record }">
        <a-space>
          <div class="table-list-img-common ">
            <c-image :src="record.picture" />
          </div>
          <div>
            <div>{{ record.productName }}</div>
            <div>{{ record.productNo }}</div>
          </div>
        </a-space>
      </template>
    </a-table>
  </div>
</template>

<script>
import Content from "../../components/Content.vue";
import { Table, Space } from "ant-design-vue";
import { toRefs, reactive } from "vue";
import CImage from "../../components/CImage.vue"
import { useI18n } from "vue-i18n/index";

export default {
  name: "product_storage",
  components: {
    ATable: Table,
    ASpace: Space,
    CImage,
    Content
  },
  props: {
    height: Number
  },
  setup (props, { emit }) {
    const vueI18n = useI18n({ useScope: "global" });

    const columns = [
      {
        slots: {
          title: "nameAndNo",//自定义header插槽名为img
          customRender: "productInfo",//自定义渲染列数据
        },
        width: 250,
      },
      {
        dataIndex: "seSku",
        title: "SESKU",
        width: 150,
      },
      {
        dataIndex: "warehouseNo",
        title: () => vueI18n.t('warehouse.warehouse_no'),
        width: 150,
      },
      {
        dataIndex: "awaitInWarehouseCount",
        align: "center",
        title: () => vueI18n.t('warehouse.await_in_warehouse_count'),
        width: 150,
      },
      {
        dataIndex: "awaitInWarehouseBoxCount",
        align: "center",
        title: () => vueI18n.t('warehouse.await_in_warehouse_box_count'),
        width: 150,
      },
      {
        dataIndex: "onWarehouseUsableCount",
        align: "center",
        title: () => vueI18n.t('warehouse.on_warehouse_usable_count'),
        width: 150,
      },
      {
        dataIndex: "onWarehouseUsableBoxCount",
        align: "center",
        title: () => vueI18n.t('warehouse.on_warehouse_usable_box_count'),
        width: 150,
      },
      {
        dataIndex: "awaitOutWarehouseCount",
        align: "center",
        title: () => vueI18n.t('warehouse.await_out_warehouse_count'),
        width: 150,
      },
      {
        dataIndex: "awaitOutWarehouseBoxCount",
        align: "center",
        title: () => vueI18n.t('warehouse.await_out_warehouse_box_count'),
        width: 150,
      },
    ];
    const data = reactive({
      productListLoading: false,
      productList: [],
    });
    return {
      columns,
      ...toRefs(data),
    };
  },
};
</script>

