<template>
  <Content>
    <template #contentTitle>
      {{ $t("warehouse.product_inventory") }}
    </template>
    <template #contentBody="wrap">
      <div>
        <!-- 统计 -->
        <div class="mb-3">
          <a-row>
            <a-col>
              <a-statistic :title="$t('warehouse.inventory_total')"
                           class="mr-5"
                           :value="warehouseTotal" />
            </a-col>
            <a-col style="border:1px solid #f0f0f0;min-width:408px;">
              <div style="display:flex;align-items: center;justify-content: space-around;">
                <div>
                  <a-statistic :title="$t('warehouse.storage_warehouse_total')"
                               class="mr-4"
                               :value-style="{ color: '#3f8600' }"
                               :value="`${storageWarehouseBoxTotal}${$t('warehouse.box')}(${storageWarehouseTotal}Unit)`" />
                </div>
                <div>
                  <a-statistic :title="$t('warehouse.consignment_warehouse_total')"
                               :value-style="{ color: '#3f8600' }"
                               :value="replaceSendWarehouseTotal" />
                </div>
              </div>
            </a-col>
          </a-row>

        </div>
        <!-- 搜索框 -->
        <a-form layout="inline">
          <a-form-item class="mb-1">
            <SearchProduct ref="refSearchProduct"></SearchProduct>
          </a-form-item>
          <a-form-item class="mb-1">
            <a-button type="primary"
                      :loading="inputSearchLoading"
                      @click="handleSearch">{{$t('common.query')}}</a-button>
          </a-form-item>
        </a-form>
        <!-- tabs -->
        <div>
          <a-tabs v-model:activeKey="tabsActiveKey"
                  @change="handleSearch">
            <a-tab-pane key="storageWarehouseTab"
                        :forceRender="true"
                        :tab="$t('warehouse.storage_warehouse_inventory')">
              <!-- list表格 -->
                <TransitWarehouseList ref="refTransitWarehouseList"
                                      v-if="tabsActiveKey=='storageWarehouseTab'" :height="wrap.contentHeight - 240"></TransitWarehouseList>
            </a-tab-pane>

            <a-tab-pane key="replaceSendWarehouseList"
                        :tab="$t('warehouse.delivery_warehouse_inventory')"
                        force-render>
              <ReplaceSendWarehouseList ref="refReplaceSendWarehouseList"
                                        v-if="tabsActiveKey=='replaceSendWarehouseList'" :height="wrap.contentHeight - 278"></ReplaceSendWarehouseList>
            </a-tab-pane>
          </a-tabs>
        </div>

      </div>
    </template>
    <template #contentFooter>
      <a-row type="flex"
             justify="space-around"
             align="middle">
        <a-col>
          <CPager @handlePage="handlePage"
                  :page-data="pageData"></CPager>
        </a-col>
      </a-row>
    </template>
  </Content>
</template>

<script>
import Content from "../components/Content.vue";
import ReplaceSendWarehouseList from "./components/ReplaceSendWarehouseList.vue";
import TransitWarehouseList from "./components/TransitWarehouseList.vue";
import { Form, Tabs, Statistic, Row, Col, Button } from "ant-design-vue";
import { toRefs, reactive, onMounted, ref, nextTick } from "vue";
import CPager from "../components/CPager.vue";
import { getProductInventoryTotal, getTransitWarehouseList, getReplaceSendWarehouseList } from "../../api/modules/product/index";
import SearchProduct from "../components/SearchProduct.vue"

export default {
  name: "product_inventory",
  components: {
    CPager,
    ReplaceSendWarehouseList,
    TransitWarehouseList,
    SearchProduct,
    Content,
    ARow: Row,
    ACol: Col,
    AButton: Button,
    AForm: Form,
    AFormItem: Form.Item,
    AStatistic: Statistic,
    ATabs: Tabs,
    ATabPane: Tabs.TabPane,

  },
  setup (props, { emit }) {
    const refReplaceSendWarehouseList = ref();
    const refTransitWarehouseList = ref();
    const refSearchProduct = ref();

    const state = reactive({
      inputSearchLoading: false
    });

    const data = reactive({
      tabsActiveKey: "storageWarehouseTab",
      warehouseTotal: 0,//库存总计
      storageWarehouseBoxTotal: 0,//存储仓总计箱
      storageWarehouseTotal: 0,//存储仓总计产品数量
      replaceSendWarehouseTotal: 0,//配送仓库合计
      inputSearch: "",
      pageData: {
        currentIndex: 1,
        skipCount: 0,
        totalCount: 0,
        maxResultCount: 10
      },
    });

    const getTotal = async () => {
      getProductInventoryTotal().then((res) => {
        data.warehouseTotal = res.result.inventoryTotal + " Unit";
        data.storageWarehouseBoxTotal = res.result.storageWarehouseBoxTotal;
        data.storageWarehouseTotal = res.result.storageWarehouseTotal;
        data.replaceSendWarehouseTotal = res.result.deliveryWarehouseTotal + " Unit";
      });
    };

    const handlePage = async (pageData) => {
      data.pageData.skipCount = pageData.skipCount;
      data.pageData.maxResultCount = pageData.maxResultCount;
      funcSearchList();
    }

    const handleSearch = () => {
      data.pageData.skipCount = 0;
      data.pageData.currentIndex = 1;
      funcSearchList();
    }

    const funcSearchList = async () => {
      let searchData = {
        "productId": refSearchProduct.value.selectedProductId,
        ...data.pageData
      };
      if (data.tabsActiveKey == "storageWarehouseTab") {
        nextTick(() => {
          funcStorageInventorys(searchData);
        });
      } else {
        nextTick(() => {
          funcConsignmentInventorys(searchData);
        });
      }
    };

    const funcStorageInventorys = (searchData) => {
      state.inputSearchLoading = true;
      refTransitWarehouseList.value.productListLoading = true;
      getTransitWarehouseList(searchData).then((res) => {

        let { items, totalCount } = res.result;
        if (res.result) {
          refTransitWarehouseList.value.productList = items.map((x, index) => {
            return {
              key: index + 1,
              picture: x.imgUrl,
              productName: x.productName,
              productNo: x.productNo,
              warehouseNo: x.warehouseNo,
              seSku: x.seSku,
              awaitInWarehouseCount: x.awaitInWarehouseCount,
              awaitInWarehouseBoxCount: x.awaitInWarehouseBoxCount,
              onWarehouseUsableCount: x.onWarehouseUsableCount,
              onWarehouseUsableBoxCount: x.onWarehouseUsableBoxCount,
              awaitOutWarehouseCount: x.awaitOutWarehouseCount,
              awaitOutWarehouseBoxCount: x.awaitOutWarehouseBoxCount
            }
          });

          data.pageData.totalCount = parseInt(totalCount);
        } else {
          refTransitWarehouseList.value.data.productList = [];
          data.pageData.totalCount = 0;
        }

      }).catch((res) => { }).finally(() => {
        state.inputSearchLoading = false;
        refTransitWarehouseList.value.productListLoading = false;
      });
    }

    const funcConsignmentInventorys = (searchData) => {
      state.inputSearchLoading = true;
      refReplaceSendWarehouseList.value.productListLoading = true;
      getReplaceSendWarehouseList(searchData).then((res) => {

        let { items, totalCount } = res.result;
        if (res.result) {
          refReplaceSendWarehouseList.value.productList = items.map((x, index) => {
            return {
              key: index + 1,
              picture: x.imgUrl,
              productName: x.productName,
              productNo: x.productNo,
              warehouseNo: x.warehouseNo,
              seSku: x.seSku,
              awaitInWarehouseCount: x.awaitInWarehouseCount,
              awaitInWarehouseBoxCount: x.awaitInWarehouseBoxCount,
              onWarehouseCount: x.onWarehouseCount,
              onWarehouseUsableCount: x.onWarehouseUsableCount,
              onWarehouseUnusableCount: x.onWarehouseCount - x.onWarehouseUsableCount,
              awaitOutWarehouseCount: x.awaitOutWarehouseCount,
            }
          });
          data.pageData.totalCount = parseInt(totalCount);
        } else {
          refReplaceSendWarehouseList.value.data.productList = [];
          data.pageData.totalCount = 0;
        }

      }).catch((res) => { }).finally(() => {
        state.inputSearchLoading = false;
        refReplaceSendWarehouseList.value.productListLoading = false;
      });
    }

    onMounted(async () => {
      getTotal();
      funcSearchList();
    })

    return {
      refSearchProduct,
      refReplaceSendWarehouseList,
      refTransitWarehouseList,
      ...toRefs(data),
      ...toRefs(state),
      funcSearchList,
      handlePage,
      handleSearch
    };
  },
};
</script>
<style lang="less" scoped>
.inputSearch {
  width: 20%;
  min-width: 240px;
}
</style>
